import React from 'react';
import ProfileCarousel from "./ProfileCarousel";
import Footer from './Footer';

const Body = () => {
    return (
        <main>
            <ProfileCarousel />
            <Footer/>
        </main>
    );
};

export default Body;
