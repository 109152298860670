import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Profile from "./Profile";
import {useDispatch, useSelector} from "react-redux";
import {fetchModels} from "../Store";
import {setCookie, getCookie} from "../utils";
import Dialog from "./Dialog";
import axios from "axios";

const t = window.t;

const submitInvitationCode = async (code, setInvalidCode) => {
    const response = await axios.get(`/api/invitation-code?code=${code}`);
    setInvalidCode(true);
    return {};
}

const addToWaitlist = async (email, setEmailInputComplete, setEmailInputInvalid) => {
    if (email) {
        if (email.indexOf("@") == -1 || email.indexOf("@") == 0) {
            setEmailInputInvalid(true);
            return;
        }
        setEmailInputInvalid(false);
        const response = await axios.post('/api/wait-list', {email});
        setEmailInputComplete(true);
    }
    return {};
}

const ProfileCarousel = () => {

    let profileCarouselSlid = getCookie('profilecarouselslid');

    const [hasSlid, setHasSlid] = useState(!!profileCarouselSlid);
    const [showWaitlist, setShowWaitlist] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [invitationCode, setInvitationCode] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [emailInputInvalid, setEmailInputInvalid] = useState(false);
    const [emailInputComplete, setEmailInputComplete] = useState(false);

    const dispatch = useDispatch();
    const {models, loading, error, location, distance} = useSelector(state => state.genericFeature);

    useEffect(() => {
        location && dispatch(fetchModels({distance, location}));
    }, [dispatch, location, distance]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const settings = {
        arrows: false,
        dots: false,
        afterChange: () => {
            window.history.replaceState(null, null, '/'); // remove it from the address bar
            if (!hasSlid) {
                setCookie("profilecarouselslid", "true", 7);
                setHasSlid(true); // Update state to reflect the change
            }
        }
    };

    const placeholder = <div className="slide-placeholder-wrapper">
        <div className="slide-placeholder">
            <div className="lds-circle">
                <div></div>
            </div>
        </div>
    </div>;

    const noOneAround = <div className="slide-no-one-around-wrapper">
        <div className="slide-no-one-around">
            <p>{t("no one around")}<br/><br/>😪</p>
        </div>
    </div>;

    const invalidCodeClass = invalidCode ? "invalid" : "";
    const invalidEmailClass = emailInputInvalid ? "invalid" : "";

    const waitlistDialog = <Dialog isOpen={!!showWaitlist}>
        <div className="dialog-waitlist-wrapper">
            <div className="dialog-waitlist">
                <p>
                    {t("invitation code needed")} <br/>
                    <input type="text" placeholder={t("invitation code")} className={invalidCodeClass}
                           onChange={(e) => {
                               setInvitationCode(e.target.value);
                               setInvalidCode(false);
                           }}/>
                    <button onClick={() => submitInvitationCode(invitationCode, setInvalidCode).then()}>Ok</button>
                </p>
                <br/>
                <p>
                    {t("invitation lacking")}
                </p>
                <p>
                    <input
                        type="email"
                        placeholder="Email"
                        disabled={emailInputComplete}
                        className={invalidEmailClass}
                        onChange={(e) => setEmailInput(e.target.value)}
                    />
                    <button disabled={emailInputComplete}
                            onClick={() => addToWaitlist(emailInput, setEmailInputComplete, setEmailInputInvalid).then()}>{t("submit")}</button>
                    {emailInputComplete && <div>{t("complete")}</div>}
                </p>
            </div>
        </div>
    </Dialog>

    return (<>
            <Slider {...settings} className={`profile-carousel ${hasSlid ? '' : 'not-slid'}`}>
                {(loading || !location) && placeholder}
                {!loading && models.length === 0 && noOneAround}
                {models.length && models.map((m, index) => {
                    return <Profile
                        name={m.name}
                        location={m.location}
                        photo={m.photo}
                        price={m.price}
                        key={index}
                        openWaitlistDialog={(v) => setShowWaitlist(v)}
                    />;
                })}
            </Slider>
            {waitlistDialog}
        </>
    );
};

export default ProfileCarousel;
