import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Prevents screen readers from reading behind the modal

const Dialog = ({isOpen, close, children}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={close}
            style={{
                content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    background: "black",
                    border: "2px solid #fed801",
                    borderRadius: "14px",
                    maxWidth: "80%",
                    color: "#fff9eb"
                }
            }}
        >
            {children}
            {close && <button className="button-with-shadow" onClick={close}>Close</button>}
        </Modal>
    );
};

export default Dialog;
