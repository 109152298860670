import React from 'react';
import {useSelector} from "react-redux";

const t = window.t;

function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in km
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;
    return Math.ceil(distance); // Rounding up to the nearest integer
}

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

const Profile = (props) => {
    const {location} = useSelector(state => state.genericFeature);
    const distance = calculateDistance(location.latitude, location.longitude, props.location[0], props.location[1]);
    return (
        <div className="profile-wrapper">
            <div className="photo-wrapper">
                <img className="profile-img" src={props.photo} alt="Profile" />
                <p className="profile-price">€{props.price}/{t("month")}</p>
            </div>
            <div className="profile-info">
                <p className="profile-name">{props.name}</p>
                <p className="profile-distance">
                    <span className="profile-km">{distance} {t("km")}</span>
                    <span className="profile-away">{t("away")}</span>
                </p>
            </div>
            <div className="profile-ctas">
                <p className="profile-view" onClick={props.openWaitlistDialog}>{t("view profile")}</p>
                <p className="profile-subscribe" onClick={props.openWaitlistDialog}>{t("subscribe")}</p>
            </div>
        </div>
    );
};

export default Profile;
