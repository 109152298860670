import React, {useState, useEffect} from 'react';
import './App.scss';
import Header from './components/Header';
import Body from './components/Body';
import Dialog from './components/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import {setLocation, setLocationError, setLocationModal} from './Store';

const t = window.t;

async function getLocation(dispatch, askPermission) {
    const permissionStatus = await navigator.permissions.query({name: 'geolocation'});
    let locationPermission = false;

    if (permissionStatus.state == "granted") {
        locationPermission = true;
    }

    if (!askPermission && !locationPermission) {
        dispatch(setLocationModal(true));
    } else if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const {latitude, longitude} = position.coords;
                dispatch(setLocation({latitude, longitude}));
                dispatch(setLocationModal(false));
            },
            (error) => {
                dispatch(setLocationError(error.message));
                dispatch(setLocationModal(true));
            }
        );
    } else {
        dispatch(setLocationError("Geolocation is not supported by this browser."));
    }
}

function App() {
    const dispatch = useDispatch();
    const {locationModal} = useSelector(state => state.genericFeature);

    useEffect(() => {
        getLocation(dispatch, false).then();
    }, []);

    return (
        <div className="App">
            <Header/>
            <Body/>
            <Dialog isOpen={!!locationModal}>
                <div className="dialog-no-location-wrapper">
                    <div className="dialog-no-location">
                        <p>{t("to use")}</p>
                        <button className="button-with-shadow" onClick={() => getLocation(dispatch, true).then()}>
                            {t("enable location")}
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default App;
