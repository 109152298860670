import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// ------------------------------------------------------------------------------------------

export const fetchModels = createAsyncThunk(
    'genericFeature/fetchModels',
    async ({distance, location}) => {
        let model_id = window.location && window.location.pathname;
        model_id = model_id.replace("/", "");
        const response = await axios.get(`/api/models?distance=${distance}&location=${location.latitude},${location.longitude}&model=${model_id}`);
        return response.data;
    }
);

export const genericSlice = createSlice({
    name: 'genericFeature',
    initialState: {
        distance: 10,
        models: [],
        loading: false,
        error: null,
        location: false,
        locationError: false,
        locationModal: false
    },
    reducers: {
        setDistance: (state, action) => {
            state.distance = action.payload;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setLocationError: (state, action) => {
            state.locationError = action.payload;
        },
        setLocationModal: (state, action) => {
            state.locationModal = action.payload;
        },
    },
    extraReducers: {
        [fetchModels.pending]: (state) => {
            state.loading = true;
        },
        [fetchModels.fulfilled]: (state, action) => {
            state.models = action.payload;
            state.loading = false;
        },
        [fetchModels.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        }
    }

});

export const { setDistance, setLocation, setLocationError, setLocationModal } = genericSlice.actions;

// ------------------------------------------------------------------------------------------

export const store = configureStore({
    reducer: {
        genericFeature: genericSlice.reducer
    },
});

// ------------------------------------------------------------------------------------------
