import React, {useState} from 'react';
import {setCookie} from "../utils";

const Header = () => {
    const [value, setValue] = useState(window.selectedLanguage);
    const handleChange = (event) => {
        setValue(value);
        setCookie("language", event.target.value, 100);
        window.location.reload();
    };

    return (
        <header>
            <img className="logo" src="logo.svg" alt="Logo"/>
            <div className="language-selector">
                <select value={value} onChange={handleChange}>
                    <option value="en">🇬🇧</option>
                    <option value="de">🇩🇪</option>
                    <option value="fr">🇫🇷</option>
                    <option value="pl">🇵🇱</option>
                    <option value="ro">🇷🇴</option>
                    <option value="hu">🇭🇺</option>
                    <option value="bg">🇧🇬</option>
                </select>
            </div>
        </header>
    );
};

export default Header;
