import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setDistance} from '../Store';

const SLIDER_MAX = 10;
const SLIDER_MIN = 2;

const t = window.t;

const getProgressStyle = val => {
    let progress;
    switch (val) {
        case 2:
            progress = 0;
            break;
        case 6:
            progress = 50;
            break;
        default:
            progress = 100;
    }
    return {background: `linear-gradient(to right, #fed801 ${progress}%, #fff9eb ${progress}%)`};
}

async function reverseGeocode(lat, lon) {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error during reverse geocoding:', error);
        return null;
    }
}


const DEFAULT_PROGRESS = getProgressStyle(SLIDER_MAX);

const Footer = () => {
    const [value, setValue] = useState(SLIDER_MAX);
    const [progressStyle, setProgressStyle] = useState(DEFAULT_PROGRESS);
    const {location} = useSelector(state => state.genericFeature);

    const dispatch = useDispatch();

    const [locationString, setLocationString] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!location) return;
            const result = await reverseGeocode(location.latitude, location.longitude);
            let locStr = "";
            if (result && result.address) {
                locStr = result.address.city;

                if (result.address.suburb) {
                    locStr = result.address.suburb + ", " + locStr;
                }
                setLocationString(locStr);
            }
        };
        fetchData().then();
    }, [location]);

    const handleChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        dispatch(setDistance(newValue));

        setProgressStyle(getProgressStyle(newValue));
        setValue(newValue);
    };


    return (
        <footer>
            <div className="footer-slider">
                <span className="footer-desc">
                    {t("filter by distance")}
                    <span className="location-as-string">{locationString ? "(" + locationString + ")" : ""}</span>
                </span>
                <div className="slider-wrapper">
                    <div className="slider-input-wrapper">
                        <input
                            id="footer-slider-input"
                            type="range"
                            min={SLIDER_MIN}
                            max={SLIDER_MAX}
                            step="4"
                            value={value}
                            onChange={handleChange}
                            style={progressStyle}
                        />
                    </div>
                    <div className="slider-value-wrapper">
                        <span>{value}
                            <span className="km-label">{t("km")}</span>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
